import React from "react";
import { Form, Input, Button, Typography } from "antd";
import "./ContactSection.scss";

const { Title } = Typography;

const ContactSection: React.FC = () => {
  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
    // Handle form submission
  };

  return (
    <section id="contact-section" className="contact-section">
      {/* Add contact form */}
      <Title level={2} className="contact-title">
        Contact Us
      </Title>
      <Form
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        className="contact-form"
      >
        <Form.Item
          label="Your Name"
          name="name"
          rules={[{ required: true, message: "Please input your name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            { required: true, message: "Please input your email!" },
            { type: "email", message: "Please enter a valid email address!" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Message"
          name="message"
          rules={[{ required: true, message: "Please input your message!" }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" className="contact-submit">
            Send Message
          </Button>
        </Form.Item>
      </Form>
    </section>
  );
};

export default ContactSection;
