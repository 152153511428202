// src/components/Services.tsx

import React from "react";
import { Row, Col } from "antd";
import "./ServicesSection.scss";

interface IService {
  icon: string;
  title: string;
  description: string;
}

const servicesData: IService[] = [
  {
    icon: "https://res.cloudinary.com/dzcmadjl1/image/upload/v1607517745/DevBloom/Icons/web-development-icon.png",
    title: "Web Development",
    description:
      "We build modern, responsive web applications using the latest technologies and best practices.",
  },
  {
    icon: "https://res.cloudinary.com/dzcmadjl1/image/upload/v1607517745/DevBloom/Icons/mobile-development-icon.png",
    title: "Mobile Development",
    description:
      "We design and develop engaging mobile apps for both iOS and Android platforms.",
  },
  {
    icon: "https://res.cloudinary.com/dzcmadjl1/image/upload/v1607517745/DevBloom/Icons/ui-ux-design-icon.png",
    title: "UI/UX Design",
    description:
      "We create beautiful and intuitive user interfaces that enhance the user experience.",
  },
  {
    icon: "https://res.cloudinary.com/dzcmadjl1/image/upload/v1607517745/DevBloom/Icons/graphic-design-icon.png",
    title: "Graphic Design",
    description:
      "We craft stunning visual designs that help your brand stand out from the competition.",
  },
  {
    icon: "https://res.cloudinary.com/dzcmadjl1/image/upload/v1607517745/DevBloom/Icons/digital-marketing-icon.png",
    title: "Digital Marketing",
    description:
      "We help you reach your target audience and grow your business with effective digital marketing strategies.",
  },
  {
    icon: "https://res.cloudinary.com/dzcmadjl1/image/upload/v1607517745/DevBloom/Icons/branding-icon.png",
    title: "Branding",
    description:
      "We develop unique and memorable branding that represents your company and resonates with your audience.",
  },
];

const Services: React.FC = () => {
  return (
    <div className="services" id="services-section">
      <h2 className="section-title">Our Services</h2>
      <Row gutter={[32, 32]} justify="center">
        {servicesData.map((service, index) => (
          <Col xs={24} sm={12} md={8} lg={6} key={index}>
            <div className="service-card">
              <img
                className="service-icon"
                src="https://www.searchenginejournal.com/wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej-1520x800.webp"
                alt={service.title}
              />
              <h3 className="service-title">{service.title}</h3>
              <p className="service-description">{service.description}</p>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Services;
