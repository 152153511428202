import React, { useState } from "react";
import { Layout, Menu, Button, Drawer, Row, Col } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { Link } from "react-scroll";
import "./Header.scss";
import logo from "@/images/logo.jpg";

const { Header: AntHeader } = Layout;

const scrollOptions = {
  duration: 500,
  smooth: true,
  offset: -64,
  spy: true,
};

const menuItems = [
  { to: "hero-section", label: "Home" },
  { to: "services-section", label: "Services" },
  { to: "portfolio-section", label: "Portfolio" },
  { to: "about-us-section", label: "About Us" },
  { to: "contact-section", label: "Contact" },
];

const Header: React.FC = () => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <AntHeader
      className="header"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <Link to="hero-section" {...scrollOptions} className="logo">
        <div
          className="logo-img"
          style={{ backgroundImage: `url(${logo})` }}
        ></div>
        <span>DEVBloom</span>
      </Link>
      <Row align="middle">
        <Col xs={0} md={20}>
          <Menu mode="horizontal" className="menu-desktop">
            {menuItems.map((item) => (
              <Menu.Item key={item.to}>
                <Link to={item.to} {...scrollOptions} activeClass="active">
                  {item.label}
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </Col>
        <Col xs={4} md={0}>
          <Button
            className="menu-mobile-button"
            type="primary"
            icon={<MenuOutlined />}
            onClick={showDrawer}
          />
        </Col>
      </Row>
      <Drawer
        title="Menu"
        placement="right"
        onClose={onClose}
        visible={visible}
        className="menu-mobile"
      >
        <Menu mode="vertical">
          {menuItems.map((item) => (
            <Menu.Item key={item.to} onClick={onClose}>
              <Link to={item.to} {...scrollOptions} activeClass="active">
                {item.label}
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      </Drawer>
    </AntHeader>
  );
};

export default Header;
