// src/components/Hero.tsx

import React from "react";
import { Button } from "antd";
import { Link } from "react-scroll";
import heroImage from "@/images/hero.png";
import "./HeroSection.scss";

const Hero: React.FC = () => {
  return (
    <section
      id="hero-section"
      className="hero"
      style={{ backgroundImage: `url(${heroImage})` }}
    >
      <div className="hero-content">
        <h1>Devbloom</h1>
        <p>We build beautiful and functional websites and applications</p>
        <Link to="services" smooth duration={1000}>
          <Button className="hero-button" size="large">
            Learn More
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default Hero;
