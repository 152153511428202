import React, { useEffect } from "react";
import { Layout } from "antd";
import { Events, scrollSpy } from "react-scroll";

import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import ServicesSection from "./components/ServicesSection";
import PortfolioSection from "./components/PortfolioSection";
import AboutUsSection from "./components/AboutUsSection";
import ContactSection from "./components/ContactSection";
import Footer from "./components/Footer";

import "./App.scss";

const { Content } = Layout;

const App: React.FC = () => {
  useEffect(() => {
    Events.scrollEvent.register("begin", function () {});
    Events.scrollEvent.register("end", function () {});

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);
  return (
    <Layout>
      <Header />
      <Content className="app-content">
        <HeroSection />
        <ServicesSection />
        <PortfolioSection />
        <AboutUsSection />
        <ContactSection />
      </Content>
      <Footer />
    </Layout>
  );
};

export default App;
