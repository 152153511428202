import React from "react";
import { Row, Col } from "antd";
import "./AboutUsSection.scss";

const About: React.FC = () => {
  return (
    <div className="about" id="about">
      <Row justify="center" align="middle">
        <Col xs={24} md={12}>
          <div className="about-image">
            <img
              src="https://www.searchenginejournal.com/wp-content/uploads/2022/06/image-search-1600-x-840-px-62c6dc4ff1eee-sej-1520x800.webp"
              alt="About Us"
            />
          </div>
        </Col>
        <Col xs={24} md={12}>
          <div className="about-content">
            <h2 className="section-title">About Us</h2>
            <p className="about-description">
              DevBloom is a web development company based in the heart of
              Silicon Valley. We specialize in creating beautiful, responsive
              websites and mobile applications for businesses of all sizes. Our
              team of experienced developers, designers, and product managers
              work together to ensure that every project we take on is completed
              to the highest standards.
            </p>
            <p className="about-description">
              At DevBloom, we are passionate about technology and innovation,
              and we are committed to delivering exceptional results for our
              clients. We take pride in our work and strive to exceed our
              clients' expectations every step of the way.
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default About;
