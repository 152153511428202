import React from "react";
import { Layout, Typography } from "antd";
import "./Footer.scss";

const { Footer: AntFooter } = Layout;
const { Text } = Typography;

const Footer: React.FC = () => {
  return (
    <AntFooter className="footer">
      <Text className="footer-text">
        &copy; {new Date().getFullYear()} DevBloom. All Rights Reserved.
      </Text>
    </AntFooter>
  );
};

export default Footer;
