import React from "react";
import { Row, Col, Card, Typography } from "antd";
import "./PortfolioSection.scss";

const { Title } = Typography;

const PortfolioSection: React.FC = () => {
  return (
    <section id="portfolio-section" className="portfolio-section">
      {/* Add portfolio projects */}
      <Title level={2} className="portfolio-title">
        Our Portfolio
      </Title>
      <Row gutter={[16, 16]} className="portfolio-row">
        <Col span={8}>
          <Card className="portfolio-card">
            <Title level={3}>Project 1</Title>
          </Card>
        </Col>
        <Col span={8}>
          <Card className="portfolio-card">
            <Title level={3}>Project 2</Title>
          </Card>
        </Col>
        <Col span={8}>
          <Card className="portfolio-card">
            <Title level={3}>Project 3</Title>
          </Card>
        </Col>
      </Row>
    </section>
  );
};

export default PortfolioSection;
